import React from 'react'

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <span dangerouslySetInnerHTML={createMarkup(props)} />;
}

const HtmlBox = ({ className, text }) => (
    <div className={className}>
        <MyComponent html={text} />
    </div>
);

export default HtmlBox