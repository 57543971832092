import React, { useState, useEffect } from "react";
import Stack from 'react-bootstrap/Stack';
import ButtonLink from '@components/ButtonLink';
import Routes from '@components/routes';
import { onlyNumbers } from '@components/utils/CleanedHtml';

const ContactFooter = ({footer_content}) => {
    const teamWhatsApp = "+971 55 897 6951";
    // const [renderComponent, setRenderComponent] = useState(false);
    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])
    
    return(
        <div className="contact-footer mb-md-56 mb-lg-0">
            {/* {renderComponent && */}
                <>
                    {
                        footer_content?.help_you_heading ?
                            <h5 className="contact-footer__title mb-16">{footer_content?.help_you_heading}</h5> :
                            <h5 className="contact-footer__title mb-16">How can we <strong>help you?</strong></h5>
                    }
                <p className="contact-footer__text mb-24 mb-40">{ footer_content?.content ?  footer_content?.content : "With our expertise in the real estate market in Dubai we assure you of our best support always.  We enjoy what we do and we do it well."} </p>
                </>                
             {/* } */}
            <Stack direction="vertical" className="gap-16 gap-md-24 flex-md-row flex-md-wrap">
                <ButtonLink manualLink={`${Routes.ContactUsPage}`} variant="secondary"btnClass="text-nowrap">{ footer_content?.contact_us ? footer_content?.contact_us : "Contact Us"} </ButtonLink>
                <ButtonLink func="ContactUsModal" variant="secondary"btnClass="text-nowrap">{footer_content?.email_us ?  footer_content?.email_us : "Email Us" }</ButtonLink>
                <ButtonLink manualLink={`https://wa.me/${onlyNumbers(teamWhatsApp)}`} variant="whatsapp"btnClass="text-nowrap">{footer_content?.whatsapp_us  ? footer_content?.whatsapp_us : "WhatsApp Us" }</ButtonLink>
                <ButtonLink manualLink={`/media/uae-property-news-letter/#subscribeNewsletterForm`} id="subscribeBtn" icon="emailIcon" label={footer_content?.News_Letter  ? footer_content?.News_Letter : "Subscribe to Newsletter"}  variant="secondary" btnClass="text-nowrap" />                                          
            </Stack>  
        </div>
    )
}

export default ContactFooter
