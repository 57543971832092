import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FooterSocialLinks from './FooterSocialLinks';
import FooterCopyright from './FooterCopyright';
import PopularSearches from '@components/popularSearches';
import ContactFooter from './ContactFooter';
import MenusFooter from './MenusFooter';
import Cookies from "@components/Cookies/Cookies"
import  "./footer.scss";
const Footer = ({popularSearch, footer_content, enbaleRightAlign}) => {
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
    }, [])
    return (
        <footer className="footer py-40 py-md-56 py-lg-96" id="footer">
            { renderComponent && <Cookies /> }
            <Container fluid="lg">
                
                <Row className="mb-36 mb-md-56 mb-lg-88">
                    <Col lg={enbaleRightAlign ? 5 : 6}><ContactFooter footer_content={footer_content} /></Col>
                    <Col lg={6}><MenusFooter footer_content={footer_content} /></Col>
                </Row>
                <Row className="mb-40 mb-md-56 mb-lg-96">
                    <Col>
                    {/* { renderComponent &&  */}
                        <PopularSearches popularSearch={popularSearch} trending_searches={footer_content?.trending_searches}/>
                    {/* } */}
                    </Col>
                </Row>
                <Row>
                    <Col xl={{ span: 7, order: 2 }}><FooterSocialLinks /></Col>
                    <Col xl={{ span: 5, order: 1 }}><FooterCopyright footer_content={footer_content} /></Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer