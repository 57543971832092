import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router"
//import loadable from "@loadable/component";
import ButtonLink from '@components/ButtonLink';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Stack from 'react-bootstrap/Stack';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { LogoIcon, SearchIcon, MenuIcon } from "@components/icons";
import Routes from '@components/routes';
import Navigation from '@components/navigation';
import { StaticImage } from "gatsby-plugin-image";
import Select from 'react-select';
import { isMobile } from "react-device-detect"
import "./header.scss";
import UK from '../../images/uk-flag.png'
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "@reach/router"
import { Helmet } from "react-helmet"
import Blue_Trans from "../../images/Blue_Trans.jpg";
import { StarberryIcons } from "@components/icons/index";
import { uaenumberformat } from '@components/utils/CleanedHtml';

//const Select = loadable(() => import("react-select"));

export const useScrollHandler = () => {
    const [scrollDir, setScrollDir] = useState(true);
    const [scrollPos, setScrollPos] = useState(false);


    // running on mount
    useEffect(() => {
        // So I just defined a threshold point with the initial value of 0 then whenever the scroll goes up or down
        // it will make the new calculation you can increase it if you don't want to immediately calculate new page offset.
        const threshold = 0;
        let lastScrollY = window.pageYOffset;
        let ticking = false;

        // In the updateScrollDir function, we will simply check if the threshold is met or not,
        // then if it met I will specify the scroll direction base of the current and previous page offset.
        const updateScrollDir = () => {
            const scrollY = window.pageYOffset;

            if (Math.abs(scrollY - lastScrollY) < threshold) {
                ticking = false;
                return;
            }
            setScrollPos(scrollY > 50 ? true : false);
            setScrollDir(scrollY > 50 && scrollY > lastScrollY ? false : true);
            lastScrollY = scrollY > 0 ? scrollY : 0;
            ticking = false;
        };

        // We just used requestAnimationFrame to make sure that we are calculating the new offset
        // after the page got rendered completely after scroll. And then with ticking flag,
        // we will make sure we are just run our event listener callback once in each requestAnimationFrame
        const onScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(updateScrollDir);
                ticking = true;
            }
        };

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    return ([scrollDir, scrollPos])
}

function MenuSidebar({ ...props }) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [renderComponent, setRenderComponent] = useState(false);
    const [injectChat, setInjectChat] = useState(true);
    var { pathname } = useLocation()

    useEffect(() => {
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })


    }, [])

    var scriptHasRun = false;
    
    var schemaName = "Exclusive Links"
    var schemaDesc = "Buy, sell or rent the best off plan properties with our real estate brokers in Dubai. Our team of real estate agents in Dubai offer customized property services for all."
    var URL = "https://www.exclusive-links.com/"
    if (pathname === "/property-management/") {
        schemaName = "Property Management Companies in Dubai"
        schemaDesc = "Transform your property management journey with Exclusive Links, the trusted property management company in Dubai UAE. Delve into our all-encompassing property management solutions, assuring your investments are handled with precision by seasoned professionals."
    }
    if (pathname === "/buy-property-in-uae/") {
        schemaName = "Buying Property in Dubai | Dubai Properties | Exclusive Links"
        schemaDesc = "Explore a diverse range of homes with Exclusive Links in Dubai. Find your dream home today by buying property in Dubai. Our expert team can guide you through the process, ensuring a smooth and informed experience."
        URL = "https://www.exclusive-links.com/buy-property-in-uae/"
    }
    return (
        <>
            {!pathname.includes("/media/uae-property-blog/") &&
                <Helmet>
                    <script type="application/ld+json">{`{
            
            "@context":"http://schema.org",
            "@type": "LocalBusiness",
            "name": "${schemaName}",
            "image": "${Blue_Trans}",
            "telephone": ["+971 4 399 4937"],
            "areaServed": "Dubai, Dubai Marina, Abu Dhabi, Motor City, Jumeirah Golf Estate, Victory Heights, Palm Jumeirah",
            "url": "${URL}",
            "description": "${schemaDesc}",
             "address" : {
                "@type" : "PostalAddress",
                "streetAddress" : " Control Tower",   
                "addressLocality" : "Motor City Dubai",  
                "postalCode" : " 3401"
              },
             "openingHoursSpecification" : {
                "@type" : "OpeningHoursSpecification",
                "dayOfWeek" : {
                  "@type" : "DayOfWeek",
                  "name" : "Mo-Thu 09:00-18:00 Fr 09:00-14:00"
               }
              },
            "sameAs": [
             "https://www.facebook.com/ELREDubai",
             "https://www.instagram.com/exclusivelinks/",
             "https://twitter.com/ELREDubai",
             "https://vm.tiktok.com/TXPwYp/",
             "https://propertytalkfromlouiseslounge.buzzsprout.com/",
             "https://www.youtube.com/user/ELREDubai",
             "https://www.linkedin.com/company/exclusive-links-real-estate-brokers",
             "https://feeds.feedburner.com/exclusive-links/feeds"
            ]
            
        }`}
                    </script>
                    {/* { renderComponent && (
               <div>
                   <div data-key='9FkRftYR32fYfanf7WvD' id="chat_js_lib"></div><script defer type="text/javascript" src="https://www.widgetinstall.com/plugin/chat.js" data-key="9FkRftYR32fYfanf7WvD" id="chat_js_lib"></script>
               </div>         
            )} */}

                </Helmet>
            }

            {/* loading chatbit */}

            <Button variant="link" onClick={handleShow} className="header__icons__icon me-2 sidebar-trigger">
                <MenuIcon />
            </Button>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body>
                    <Navigation handleClose={handleClose} show={show} limit={false} />
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

const Header = (props) => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        popularSearch(publicationState: LIVE) {
            Country_Menu {
                Show
                label
                value
                icon {
                  url
                }
              }
          }
      }
    }
  `)

    //   const [renderComponent, setRenderComponent] = useState(false);

    //   useEffect(() => {
    //     window.addEventListener("mousemove", () => {
    //       if (renderComponent === false) {
    //         setRenderComponent(true)
    //       }
    //       })
    //       window.addEventListener("touchmove", () => {
    //           if (renderComponent === false) {
    //           setRenderComponent(true)
    //           }
    //       })
    //       window.addEventListener("keypress", () => {
    //           if (renderComponent === false) {
    //           setRenderComponent(true)
    //           }
    //       })
    //   }, [])
    const options = data.glstrapi.popularSearch.Country_Menu;
    const {
        classNameHeader
    } = props

    var filteredFlags = options.filter((x) => x.Show === true)
    const [flags, setFlags] = useState(filteredFlags)

    var { pathname } = useLocation()
    var path = pathname.replaceAll("/", "");
    var menulistChange = flags?.filter(x => x.label === path)
    var defaultValue = menulistChange.length > 0 ? menulistChange : flags[0]

    const [status, setStatus] = useState(false)

    const getFlagControl = () => {
        const defaultFlag = [
            { value: "UK", label: "", icon: UK },
        ]
        const currentFlag = path && flags.find((x) => { return x?.label === path });
        if (currentFlag) {
            setFlags(defaultFlag)
            setStatus(true)
        }
    }
    useEffect(() => {
        getFlagControl()


    }, [flags])

    const [showHeader, setShowHeader] = useState(false);
    useEffect(() => {
        setShowHeader(true);
    }, [])
    // calling our scroll hook
    const scrollDirection = useScrollHandler()[0];
    const scrollPosition = useScrollHandler()[1];

    // open dropdown on hover
    const [show, setShow] = useState(false);
    const showDropdown = (e) => {
        setShow(!show);
    }
    const hideDropdown = (e) => {
        setShow(false);
    }

    const hanldeflagchange = (e) => {
        console.log(e)
        navigate(`/${e.label}`, { replace: true })
    }
    return (
        <header className={`fixed-top header${classNameHeader ? ` ${classNameHeader}` : ''} ${props.loading ? "blu-clr" : ""}  ${scrollDirection ? " header-scroll" : " header-scroll"}${typeof window !== 'undefined' && window?.pageYOffset > 500 ? " header-dark" : ""} ${scrollPosition ? " header-dark" : ""}${showHeader ? " visible" : ""}`}>
            {/* <script type="text/javascript" src="https://www.bugherd.com/sidebarv2.js?apikey=rq2n6fmt6hlpgk1re2jt9a" async={true}></script> */}
            {/* {renderComponent && */}
            <StaticImage
                src="../../images/pattern.jpg"
                formats={["auto", "webp"]}
                alt="Background Image - Exclusive Links"
                className="pattern-img"
            />
            {/* } */}
            <Container fluid="lg" className="header-content">
                <Row>
                    <Col>
                        <Stack direction="horizontal" gap={3}>
                            <ButtonLink manualLink="/" variant="none">
                                <LogoIcon className="icon-logo" />
                            </ButtonLink>
                            <Stack className="d-flex">
                                {!isMobile &&
                                    <Stack direction="horizontal" className="ms-auto gap-5 gap-xl-50 __flag_wrapper">
                                        {
                                            flags.map((flag, i) => {
                                                return (
                                                    <ButtonLink manualLink={`/${flag?.label ? flag?.label : ""}`} variant="link-secondary" btnClass="d-none d-xl-block header__link" >
                                                        <img src={flag?.icon?.url ? flag?.icon?.url : flag?.icon} alt="flags" />
                                                    </ButtonLink>
                                                )
                                            })

                                        }
                                        <ButtonLink manualLink={`tel:${uaenumberformat(97143994937)}`} variant="link-secondary" btnClass="d-none d-xl-block header__link">{uaenumberformat("+9714-3994937")}</ButtonLink>
                                        {
                                            props.enbaleRightAlign ? "" :
                                                <ButtonLink func="PropertySearchModal" variant="link" btnClass="d-none d-xl-block header__icons__icon">
                                                    <SearchIcon className="icon-search" />
                                                </ButtonLink>
                                        }
                                        {
                                            props.enbaleRightAlign ? "" : <MenuSidebar placement="end" className="header__icons__icon sidebar-trigger" />
                                        }
                                    </Stack>
                                }
                                <div className="d-flex" >
                                    <Stack direction="horizontal" className={`ms-auto  ${status ? "gap-5 gap-xl-16 multi_country_page" : "gap-5 gap-md-25 gap-xl-25"} ${props.enbaleRightAlign && "_enable_Right_Align_Menu gap-5 gap-xl-26"}  __content_wrapper`}>
                                        {!status &&
                                            <>
                                                <ButtonLink manualLink={`${Routes.Buy}/`} variant="link-secondary" btnClass="d-none d-xl-block header__link list_your_menu">Buy</ButtonLink>
                                                <ButtonLink manualLink={`${Routes.Rent}/`} variant="link-secondary" btnClass="d-none d-xl-block header__link list_your_menu">Rent</ButtonLink>
                                                <ButtonLink manualLink={`${Routes.OffPlanProperties}/`} variant="link-secondary" btnClass="d-none d-xl-block header__link list_your_menu">Off Plan</ButtonLink>
                                            </>
                                        }
                                        <ButtonLink manualLink={`${Routes.ListYourProperty}/`} variant="link-secondary" btnClass="d-none d-xl-block header__link list_your_menu">{status && props?.menu_list?.List_Your_Property ? props.menu_list?.List_Your_Property : "List Your Property"}</ButtonLink>
                                        {!status &&
                                            <>
                                                <ButtonLink manualLink={`${Routes.PropertyManagement}/`} variant="link-secondary" btnClass="d-none d-xl-block header__link list_your_menu">Property Management</ButtonLink>
                                                <ButtonLink manualLink={`${Routes.VacationHomesProperties}/`} variant="link-secondary" btnClass="d-none d-xl-block header__link list_your_menu">Vacation Homes</ButtonLink>
                                            </>
                                        }

                                        {/* <DropdownButton
                                        title={ status && props?.menu_list?.Services ? props?.menu_list?.Services : "Services"}
                                        variant="link-secondary"
                                        className="d-none d-xl-block services-dropdown"
                                        renderMenuOnMount={true}
                                        show={show}
                                        onMouseEnter={showDropdown}
                                        onMouseLeave={hideDropdown}
                                        
                                    > 
                                        <div className="services-dropdown__container">
                                            <Navigation show={true} limit={true} />
                                        </div> 
                                    </DropdownButton> */}
                                        {
                                            status && props?.menu_list?.Why_Exclusive_Links &&
                                            <ButtonLink manualLink={`#Why_Exclusive_Links`} variant="link-secondary" btnClass="d-none d-xl-block header__link why_exclusive_menu">{props.menu_list.Why_Exclusive_Links}</ButtonLink>}
                                        {
                                            status && props?.menu_list?.Why_Dubai &&
                                            <ButtonLink manualLink={`#Why_Invest_in_Dubai`} variant="link-secondary" btnClass="d-none d-xl-block header__link why_dubai_menu">{props.menu_list.Why_Dubai}</ButtonLink>
                                        }

                                        <ButtonLink manualLink={`${Routes?.ContactUsPage}/`} variant="link-secondary" btnClass={`d-none d-xl-block header__link ${status && "country_page_contact_menu"}`}>{status && props?.menu_list?.Contact ? props.menu_list.Contact : "Contact"}</ButtonLink>
                                        {/* <ButtonLink manualLink={`tel:${uaenumberformat(97143994937)}`} variant="link-secondary" btnClass="d-none d-xl-block header__link">{uaenumberformat("+9714-3994937")}</ButtonLink> */}

                                        {
                                            isMobile && (
                                                <>
                                                    <Select
                                                        className="flag_dropdown_modile_view"
                                                        defaultValue={defaultValue}
                                                        options={flags}
                                                        onChange={hanldeflagchange}
                                                        getOptionLabel={e => (
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={e.icon.url ? e.icon.url : e.icon} alt="flagIcons" height={20} width={25} />
                                                            </div>
                                                        )}
                                                    />
                                                    {props.enbaleRightAlign ? "" : <MenuSidebar placement="end" className="header__icons__icon sidebar-trigger" />}
                                                </>)
                                        }
                                    </Stack>
                                    {
                                        props.enbaleRightAlign ?
                                            <ButtonLink func="PropertySearchModal" variant="link" btnClass="d-none d-xl-block header__icons__icon">
                                                <SearchIcon className="icon-search" />
                                            </ButtonLink> : ""
                                    }
                                    {
                                        props.enbaleRightAlign ? <MenuSidebar placement="end" className="header__icons__icon sidebar-trigger" /> : ""
                                    }
                                </div>
                                <a target="_blank" href={`https://wa.me/+971527735457`}>
                                    <div direction="horizontal" className="FixedWhatsAppIcon gap-12 contact-container-link btn-icon-whatsApp">
                                            <StarberryIcons iconName="FixedWhatsAppIcon" className="whatsapp-icon" />
                                    </div>
                                </a>
                            </Stack>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

Header.defaultProps = {
    classNameHeader: ``,
}

export default Header

