import React from 'react';
import { ModalContext } from './ModalContext';

const ModalRoot = () => {
    const { component: Component, props, hideModal } = React.useContext(ModalContext);

    // console.log(Component, props, hideModal);
    if (Component) {
        return (
            <Component {...props} onRequestClose={hideModal} />
        )
    }

    return null;
}

export default ModalRoot;