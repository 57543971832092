import React from 'react';
import { StarberryIcons } from "@components/icons/index";
import Stack from 'react-bootstrap/Stack';
import './siteByStarberry.scss';

const SiteByStarberry = ({footer_content}) => {
    return (
        <a href="https://starberry.tv/" className="siteby" target="_blank" rel="noreferrer">
            <Stack direction="horizontal" className="gap-5">
                <span>{footer_content?.site_by ? footer_content?.site_by : "Site by" } </span>
                <StarberryIcons iconName="starberryIcon" />
            </Stack>
        </a>
    );
}

export default SiteByStarberry;