import React from 'react';
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Label from './label';


const InputField = ({
    className,
    type,
    nolabel,
    name,
    value,
    required,
    placeholder,
    handlechange,
    pattern,
    label,
    grpmd,
    groupClass,
    email,
    id,
    as
}) => (
    <Form.Group as={Col} md={grpmd} className={groupClass} controlId={"validation" + name} id={id}>
        {nolabel ? '' : <Label label={label} required={required} />}
        <Form.Control
            as={as}
            className={className}
            required={required}
            type={type}
            name={name}
            value={type === "email" && email ? email : value}
            placeholder={placeholder}
            onChange={handlechange}
            pattern={pattern}
            maxlength={name === "telephone" ? "20" : name === "message" ? "500"  : "30"}
        />
    </Form.Group>
);

export default InputField;