export const cleanP = (text) => {
    // remove all <p> tags from string
    return text?.replace(/<\/?p[^>]*>/g, "");
}

export const onlyNumbers = (text) => {
    // keep only numbers
    // return text?.parseInt(text)(/<\/?p[^>]*>/g, "");
    if(text){
        return text?.match(/\d+/g).join('');
    }
    else {
        return "";
    }
}

export const uaenumberformat = (x) => {
    if (!x) return x; 
    const currentValue = x?.toString()?.replace(/-/g, '')?.replace(/ /g, '')?.replace("+", '');
    if(currentValue.toString().length === 9){
        // currentValue = currentValue.slice(1, 9)
        return `+971 ${currentValue.slice(1, 2)} ${currentValue.slice(2, 5)} ${currentValue.slice(5, 9)}`; 
    }
    else if(currentValue.toString().length === 11){
        return `+${currentValue.slice(0, 3)} ${currentValue.slice(3, 4)} ${currentValue.slice(4, 7)} ${currentValue.slice(7, 11)}`; 
    }
    else if(currentValue.toString().length === 12){
        return `+${currentValue.slice(0, 3)} ${currentValue.slice(3, 5)} ${currentValue.slice(5, 8)} ${currentValue.slice(8, 12)}`; 
    }
    else {
        return x
    }
    
}