import * as React from "react";

// remove all p tags from string
const CleanedHtml = ({ html ,CarouselPage}) => {
    // remove all <p> tags from string
    const cleanedHtml = html?.replace(/<\/?p[^>]*>/g, "");

    return(
        <HtmlText html={cleanedHtml} CarouselPage={CarouselPage}/>
    )
}
function HtmlText(props) {
    //console.log("htmltextt",props)
    return (
        <>
           {props.CarouselPage === true ?  <div dangerouslySetInnerHTML={createHTMLMarkup(props)} className={props?.className} /> :

            <span dangerouslySetInnerHTML={createHTMLMarkup(props)} className={props?.className}/>
           }
        </>
    )
}
function createHTMLMarkup(props) {
    return {__html: (props.html)};
}

const ModuleTitle = (props) => {
    const {
        className,
        isBannerCarouselPage,
        title,
        as,
    } = props
    const containsH1 = (html) => /<h1\b[^>]*>(.*?)<\/h1>/i.test(html);

    const As = containsH1(title) ? "div" : (as ? as : "h2");

    return(
        <As className={className}>
            <CleanedHtml html={title} CarouselPage={isBannerCarouselPage}/>
        </As>
    )
}

export default ModuleTitle