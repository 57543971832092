import React from "react"
import { Link } from "gatsby";
import CookieConsent from "react-cookie-consent";
import './Cookies.scss';
const Cookies = (props) => {

  return (
    <React.Fragment>
      <CookieConsent
        disableStyles={true}
        buttonClasses="btn btn-primary btn-link gotit"
        containerClasses="cookies-section d-block"
        contentClasses="cokkies-txt"
        buttonText="Accept Cookies"

      >
        We have placed cookies on your device to help make this website better. By continuing, you agree to our <Link to="/privacy-and-cookie-policy">Cookie Policy</Link>.
      </CookieConsent>
    </React.Fragment>

  )
}



export default Cookies