import React from "react";
import Modal from '@components/modal';
import ContactUsForm from '@components/forms/contactUs';
import ContactMemberForm from '@components/forms/contactMember';
import LeaveReviewForm from '@components/forms/leaveReview';
import EnquirePropertyForm from '@components/forms/enquireProperty';
import AskQuestionForm from '@components/forms/askQuestion';
import SendCVForm from '@components/forms/sendCV';
import BookValuationForm from '@components/forms/bookValuation';
import LoyaltyProgramForm from '@components/forms/loyaltyProgram';
import OffPlanModalForm from "@components/forms/offplanRegister";
import SearchOverlay from "@components/property/SearchOverlay";

import ConnectPartnerForm from '@components/forms/connectPartner';

export const ConnectPartnerModal = ({ onRequestClose, show, ...otherProps }) => {
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Connect with a Partner`}
            {...otherProps}
        >
            <ConnectPartnerForm />
        </Modal>
    )
}

export const ContactModal = ({ onRequestClose, show, email, ...otherProps }) => {
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Contact`}
            {...otherProps}
        >
            <ContactUsForm email={email} />
        </Modal>
    )
}

export const ContactMemberModal = ({ onRequestClose, show, ...otherProps }) => {
    // console.log(otherProps)
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Contact`}
            {...otherProps}
        >
            <ContactMemberForm email={otherProps?.email}  />
        </Modal>
    )
}

export const LeaveReviewModal = ({ onRequestClose, show, ...otherProps }) => {
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Leave a Review`}
            {...otherProps}
        >
            <LeaveReviewForm />
        </Modal>
    )
}

export const OffPlanModal = ({ onRequestClose, show, data,  ...otherProps }) => {
   
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={data?.department === 'international_projects' ? "International Property Registration" : data?.department === 'new_development_projects' ? `Off Plan Project Registration`  : `Off Plan Property Registration`}
            {...otherProps}
        >
            <OffPlanModalForm referenceid={data?.referenceNumber ? data?.referenceNumber : data?.extra?.referenceNumber} data={data} developer_name={data?.description}  />
        </Modal>
    )
}
export const EnquirePropertyModal = ({ onRequestClose, show, data,  ...otherProps }) => {
    // console.log(data)
    let uriStr = "";
   
    if (data.search_type === "sales" && data.department === 'residential') {
        uriStr = `property-for-sale/`
      } else if (data.search_type === "lettings" && data.department === 'residential') {
        uriStr = `property-for-rent/`
      } else if (data.search_type === "lettings" && data.department === 'short_term') {
        uriStr = `short-term-property-for-rent/`
      } else if (data.search_type === "sales" && data.department === 'commercial') {
        uriStr = `commercial-property-for-sale/`
      } else if (data.search_type === "lettings" && data.department === 'commercial') {
        uriStr = `commercial-property-for-rent/`
      } else if (data.search_type === "sales" && data.department === 'new_developments') {
        uriStr = `off-plan-property-for-sale/`
      } else if (data.search_type === "offplan" && data.department === 'new_development_projects') {
        uriStr = `off-plan-project-for-sale/`
      } else if (data.search_type === "international" && data.department === 'international_projects') {
        uriStr = `international-property-for-sale/`
      }
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Enquire Now`}
            {...otherProps}
        >
            <EnquirePropertyForm  agent={data?.negotiator} url={process.env.GATSBY_SITE_URL+`/${uriStr}${data.slug}-${data.strapiId ? data.strapiId : data.id}`} description={data.description} referenceid={data.referenceNumber ? data.referenceNumber : data?.extra?.referenceNumber} area={data.area}  />
        </Modal>
    )
}

export const AskQuestionModal = ({ onRequestClose, show, ...otherProps }) => {
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Ask a Question`}
            {...otherProps}
        >
            <AskQuestionForm />
        </Modal>
    )
}

export const SendCVModal = ({ onRequestClose, show, ...otherProps }) => {
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Send us Your CV`}
            {...otherProps}
        >
            <SendCVForm />
        </Modal>
    )
}

export const BookValuationModal = ({ onRequestClose, show, ...otherProps }) => {
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Book a Valuation`}
            {...otherProps}
        >
            <BookValuationForm />
        </Modal>
    )
}

export const LoyaltyProgramModal = ({ onRequestClose, show, ...otherProps }) => {
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            backdrop="static"
            title={`Join Loyalty Program`}
            {...otherProps}
        >
            <LoyaltyProgramForm />
        </Modal>
    )
}

export const PropertySearchModal = ({ onRequestClose, show, ...otherProps }) => {
    return(
        <Modal
            show={show}
            onHide={onRequestClose}
            // title={`Join Loyalty Program`}
            centered={true}
            backdrop="static"
            keyboard={false}
            animationIn="slideInLeft"
            animationOut="slideOutRight"
            dialogClassName="search-overlay-modal"
            {...otherProps}
        >
            <div className="backdrop">
                <div className="find-property-wrapper">
                    <SearchOverlay onRequestClose={onRequestClose} />
                </div>
            </div>
        </Modal>
    )
}