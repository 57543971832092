import React from 'react';
import Stack from 'react-bootstrap/Stack';
import SiteByStarberry from './SiteByStarberry';

const FooterCopyright = ({footer_content}) => {
    return (
        <Stack direction="vertical" gap={8} className="footer__copyright">
            <div className="gap-5">
                <a href="/sitemap/">{footer_content?.Sitemap ? footer_content?.Sitemap : "Sitemap"} </a>
                <span className='mx-8'>/</span>
                <a href="/privacy-and-cookie-policy/">{footer_content?.privacy_policy ? footer_content?.privacy_policy :  "Privacy & Cookie Policy"}</a>
                <span className='mx-8'>/</span>
                <a href="/terms-and-conditions/">{footer_content?.terms_conditions ? footer_content?.terms_conditions : "Terms & Conditions" }</a>
            </div>
            {
                footer_content?.copyright && footer_content?.rights ?
                <div className='copy_write_text'>© {footer_content?.copyright} {new Date().getFullYear()}. <br className="d-block d-sm-none" />{footer_content?.rights}</div> :
                <div>© Copyright Exclusive Links {new Date().getFullYear()}. <br className="d-block d-sm-none" />All Rights Reserved.</div>

            }
            <SiteByStarberry footer_content={footer_content} />
        </Stack>
    );
}

export default FooterCopyright