import React, {useState, useEffect} from 'react';
//import loadable from "@loadable/component";
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Select from 'react-select';
import Label from './label';
//const Select = loadable(() => import("react-select"));

const selectStyle = {
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? "#fff" : "#5D5F66",
        background: state.isSelected ? "#000033" : "#fff",
        padding: 8,
        fontSize: "14px"
    }),
    control: (provided) => ({
        ...provided,
        background: "#fff",
        borderRadius: 4,
        // height: 56,
        border: "none",
        boxShadow: "0px 1px 3px -1px rgba(0, 0, 0, 0.24)",
        paddingLeft: 24,
        paddingRight: 16,
        padding: "0.25rem 1rem"
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: "#5D5F66"
    }),
    indicatorSeparator: () => ({
        display: "none"
    }),
    placeholder: (provided) => ({
        ...provided,
        color: "#5D5F66",
        whiteSpace: "nowrap",
        fontSize: "14px"
    }),
    singleValue: (provided) => {
        return {
            ...provided,
            color: "#5D5F66",
            fontSize: "14px"
        };
    },
    valueContainer: (provided) => ({
        ...provided,
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: "14px"
    })
}

const SelectField = ({
    className,
    // type,
    nolabel,
    name,
    values,
    value,
    required,
    placeholder,
    // handlechange,
    selected,
    label,
    grpmd,
    groupClass,
    selecttype,
    setSelecttype
}) => {
    const handleselect = event => {
        // remove initial empty spaces
        //console.log("select_event",event);
       
        setSelecttype('')
       
    
      }
    return (
    <Form.Group as={Col} md={grpmd} className={groupClass} controlId={"validation" + name}>
        {nolabel ? '' : <Label label={label} required={required} />}
        <Select
            options={values.length ? values : null}
            placeholder={placeholder ? placeholder : "Select..."}
            defaultValue={selecttype === "sale" ? values[0] : selecttype === "rent" ? values[1] : selecttype === "short" ? values[2] : selected}
            value={selecttype === "sale" ? values[0] : selecttype === "rent" ? values[1] : selecttype === "short" ? values[2] : value}
            onChange={handleselect}
            className={`${className} react-select`}
            classNamePrefix={"react-select"}
            styles={selectStyle}
            isSearchable={false}
        />
    </Form.Group>)
};

export default SelectField;