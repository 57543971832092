import React from 'react';
import { StarberryIcons } from "@components/icons/index";
import { Stack } from 'react-bootstrap';

const FooterSocialLinks = () => {
    return (
        <Stack direction="horizontal" gap={24} className="mb-36 mb-md-32 mb-xl-0 footer-social-links justify-content-xl-end flex-wrap">
            <a href="https://www.facebook.com/ELREDubai" target="_blank" rel="noreferrer" aria-label="Facebook page"><StarberryIcons iconName="facebookIcon" /></a>
            <a href="https://www.instagram.com/exclusivelinks/" target="_blank" rel="noreferrer" aria-label="Instagram page"><StarberryIcons iconName="instagramIcon" /></a>
            <a href="https://twitter.com/ELREDubai" target="_blank" rel="noreferrer" aria-label="Twitter page"><StarberryIcons iconName="twitterIcon" /></a>
            <a href="https://www.tiktok.com/@exclusivelinks" target="_blank" rel="noreferrer" aria-label="TikTok page"><StarberryIcons iconName="tikTokIcon" /></a>
            <a href="https://propertytalkfromlouiseslounge.buzzsprout.com/" target="_blank" rel="noreferrer" aria-label="Buzzsprout page"><StarberryIcons iconName="buzzsproutIcon" /></a>
            <a href="https://www.youtube.com/user/ELREDubai" target="_blank" rel="noreferrer" aria-label="YouTube page"><StarberryIcons iconName="youTubeIcon" /></a>
            <a href="https://www.linkedin.com/company/exclusive-links-real-estate-brokers" target="_blank" rel="noreferrer" aria-label="LinkedIn page"><StarberryIcons iconName="linkedInIcon" /></a>
            <a href="https://feeds.feedburner.com/exclusive-links/feeds" aria-label="Rss Feed"><StarberryIcons iconName="rssIcon" /></a>
        </Stack>
    );
}

export default FooterSocialLinks