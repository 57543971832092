import React from 'react';
import { Link } from "gatsby";
import Button from 'react-bootstrap/Button';
import { StarberryIcons } from "@components/icons/index";
import { ModalContext } from '@components/modal/ModalContext';
import {
    EnquirePropertyModal,
    OffPlanModal,
    ContactModal,
    AskQuestionModal,
    ConnectPartnerModal,
    SendCVModal,
    BookValuationModal,
    LoyaltyProgramModal,
    LeaveReviewModal,
    ContactMemberModal,
    PropertySearchModal
} from '@components/modal/modals';

// https://stackoverflow.com/questions/646628/how-to-check-if-a-string-startswith-another-string
// the problem with using indexOf is that if it fails matching at the start, it will continue searching
// the entire string, whereby lastIndexOf starts from the length of the word and walks back to zero.
function startsWith(str, word) {
    return str?.lastIndexOf(word, 0) === 0;
}
function switchOnClick(func, showModal, hideModal, data) {
    switch (func) {
        case "EnquirePropertyModal":
            return () => showModal(EnquirePropertyModal, { show: true, data:data })
        case "OffPlanModal":    
        return () => showModal(OffPlanModal, { show: true, data:data })
        case "ContactUsModal":
            return () => showModal(ContactModal, { show: true, title: "Contact Us" })
        case "AskQuestionModal":
            return () => showModal(AskQuestionModal, { show: true })
        case "ConnectPartnerModal":
            return () => showModal(ConnectPartnerModal, { show: true })
        case "SendCVModal":
            return () => showModal(SendCVModal, { show: true })
        case "BookValuationModal":
            return () => showModal(BookValuationModal, { show: true })
        case "LoyaltyProgramModal":
            return () => showModal(LoyaltyProgramModal, { show: true })
        case "LeaveReviewModal":
            return () => showModal(LeaveReviewModal, { show: true })
        case "ContactMemberModal":
            return () => showModal(ContactMemberModal, { show: true })
        case "PropertySearchModal":
            return () => showModal(PropertySearchModal, { show: true })
        default:
            break;
    }
}

const pagePath = (post) => {
    let pagePath;
    if (post.Parent) {
        pagePath = `/${post.Parent.Slug}/${post.Slug}/`
        if (post.Parent2) {
            pagePath = `/${post.Parent.Slug}/${post.Parent2.Slug}/${post.Slug}/`
        }
        if (post.Parent3) {
            pagePath = `/${post.Parent.Slug}/${post.Parent2.Slug}/${post.Parent3.Slug}/${post.Slug}/`
        }
    } else {
        pagePath = `/${post.Slug}/`
    }
    return pagePath
}

const ButtonLink = ({
    // id,
    btnClass,
    manualLink,
    label,
    page,
    icon,
    children,
    enableSpan,
    func,
    onClick,
    data,
    handleClose,
    burgermenu,
    ...other
}) => {

  

    const { showModal, hideModal } = React.useContext(ModalContext);
    const scrollTo = (e) => {
        e.preventDefault();
        const section = document.querySelector(`${manualLink}`);
        return(
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } )
        )
    }
    let link=manualLink?.endsWith("/") ? manualLink : manualLink?.includes("#") ? manualLink : manualLink+"/";
    if (func) { // Button should trigger an onclick function
        return (
            <Button
                to={`#`}
                className={btnClass}
                state={page?.btnState ? page.btnState : null}
                onClick={switchOnClick(func, showModal, hideModal, data)}
                {...other}
            >
                {icon && <StarberryIcons iconName={icon} className="bi" />}
                {
                    enableSpan ? <span>{label ? label : children}</span> 
                    :
                    label ? label : children
                }
            </Button>
        )
    } else if (onClick) { // Normal Button with onclick function
        return(
            <Button
                // to={`#`}
                className={btnClass}
                state={page?.btnState ? page.btnState : null}
                {...other}
                onClick={onClick}
            >
                {icon && <StarberryIcons iconName={icon} className="bi" />}
                {label ? label : children}
            </Button>
        )
    }
    else if (page && burgermenu) { // if its a normal internal page link
        return(
            <Button
                to={page?.Slug === 'home' ? '/' : `${pagePath(page)}`}
                as={Link}
                className={btnClass}
                state={page?.btnState ? page.btnState : null}
                {...other}
                onClick={handleClose}
            >
                {icon && <StarberryIcons iconName={icon} className="bi" />}
                {label ? label : children}
            </Button>
        )
    }
    else if (page) { // if its a normal internal page link
        
        return(
            <Button
                to={page?.Slug === 'home' ? '/' : `${pagePath(page)}`}
                as={Link}
                className={btnClass}
                state={page?.btnState ? page.btnState : null}
                {...other}
            >
                {icon && <StarberryIcons iconName={icon} className="bi" />}
                {label ? label : children}
            </Button>
        )
    }
    else if (manualLink && burgermenu) {
       
        
        
        const isTarget = startsWith(manualLink, '#'); // using href target link to scroll to ID
        const isRelative = startsWith(manualLink, '/'); // it's a manual relative link
        const isTel = startsWith(manualLink, 'tel:'); // it's a manual relative link
        const isMail = startsWith(manualLink, 'mailto:'); // it's a manual relative link
        const isTargetLength = manualLink.length > 1; // sometimes a link can have a dummy placeholder '#', so we want to exclude it as it's not a valid target
        // it's a target link
        if (isTarget && isTargetLength) {
            
            return(
                <Button
                    href={link}
                    className={btnClass}
                    onClick={(e) => { return scrollTo(e) }}
                    {...other}
                >
                    {icon && <StarberryIcons iconName={icon} className="bi" />}
                    {label ? label : children}
                </Button>
            )
        } else if (isRelative) {

            return(
                <Button
                    to={link}
                    as={Link}
                    className={btnClass}
                    onClick={handleClose}
                    {...other}
                >
                    {icon && <StarberryIcons iconName={icon} className="bi" />}
                    {label ? label : children}
                </Button>
            )
        } else if (isTel || isMail) {
            return(
                <Button href={manualLink} className={btnClass} {...other}>
                    {icon && <StarberryIcons iconName={icon} className="bi" />}
                    {label ? label : children}
                </Button>
            )
        } else { // it's not a target link, so it's external link
            
            return(
                <Button onClick={handleClose} href={link} className={btnClass} target="_blank" rel="noreferrer" {...other}>
                    {icon && <StarberryIcons iconName={icon} className="bi" />}
                    {label ? label : children}
                </Button>
            )
        }
    } 
    else if (manualLink) {
        const isTarget = startsWith(manualLink, '#'); // using href target link to scroll to ID
        const isRelative = startsWith(manualLink, '/'); // it's a manual relative link
        const isTel = startsWith(manualLink, 'tel:'); // it's a manual relative link
        const isMail = startsWith(manualLink, 'mailto:'); // it's a manual relative link
        const isTargetLength = manualLink.length > 1; // sometimes a link can have a dummy placeholder '#', so we want to exclude it as it's not a valid target
        // it's a target link
        if (isTarget && isTargetLength) {
            return(
                <Button
                    href={link}
                    className={btnClass}
                    onClick={(e) => { return scrollTo(e) }}
                    {...other}
                >
                    {icon && <StarberryIcons iconName={icon} className="bi" />}
                    {label ? label : children}
                </Button>
            )
        } else if (isRelative) {
            return(
                <Button
                    to={link}
                    as={Link}
                    className={btnClass}
                    {...other}
                >
                    {icon && <StarberryIcons iconName={icon} className="bi" />}
                    {
                        enableSpan ? <span>{label ? label : children}</span> 
                        :
                        label ? label : children
                    }
                </Button>
            )
        } else if (isTel || isMail) {
            return(
                <Button href={manualLink} className={btnClass} {...other}>
                    {icon && <StarberryIcons iconName={icon} className="bi" />}
                    {label ? label : children}
                </Button>
            )
        } else { // it's not a target link, so it's external link
            return(
                <Button href={manualLink} className={btnClass} target="_blank" rel="noreferrer" {...other}>
                    {icon && <StarberryIcons iconName={icon} className="bi" />}
                    {label ? label : children}
                </Button>
            )
        }
    }
    return null
}

//     // else if (externalLink) { // using external link
//     //     // const isCalendly = startsWith(externalLink, 'https://calendly.com');
//     //     // if (isCalendly) {
//     //     //     return(
//     //     //         <CalendlyButtonLink
//     //     //             label={Label}
//     //     //             url={externalLink}
//     //     //             btnClass={className}
//     //     //         />
//     //     //     )
//     //     // } else {
//     //         return(
//     //             <Fragment key={id}>
//     //                 <a href={externalLink} className={className} target="_blank" rel="noreferrer">
//     //                     {label}
//     //                 </a>
//     //             </Fragment>
//     //         )
//     //     // }
//     // } 
//     else {
//         return null
//     }
// }

export default ButtonLink