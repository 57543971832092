import React, { Component, createContext } from "react";

export const ModalContext = createContext({
    component: null,
    props: {},
    showModal: () => {},
    hideModal: () => {}
});

export class ModalProvider extends Component {
    showModal = (component, props = {}) => {
        this.setState({
            component,
            props
        });
    };

    hideModal = () =>
        this.setState({
            component: null,
            props: {}
        });

    state = {
        component: null,
        props: {},
        showModal: this.showModal,
        hideModal: this.hideModal
    };

    render() {
        return (
            <ModalContext.Provider value={this.state}>
                {this.props.children}
            </ModalContext.Provider>
        );
    }
}

// export const _ModalProvider = ({ children }) => {
//     const [props, setProps] = React.useState({});
//     const [component, setComponent] = React.useState(null);
    
//     const showModal = React.useCallback((newComponent, newProps = {}) => {
//         setComponent(newComponent);
//         //setProps(newProps);
//     }, []);

//     const hideModal = React.useCallback(() => {
//         setComponent(null);
//         setProps({});
//     }, []);

//     return (
//         <ModalContext.Provider value={{
//             props,
//             component,
//             showModal,
//             hideModal,
//         }}>
//             {children}
//         </ModalContext.Provider>
//     );
// };


export const ModalConsumer = ModalContext.Consumer;