import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Tabs, Tab, Button, Form } from "react-bootstrap";
import {isEmpty, find} from "lodash"
import { navigate } from "@reach/router";
import { StarberryIcons } from "@components/icons/index";
import SearchListBox from "@components/PredictiveSearch/search-multiarea";
import AreaListBox from "@components/PropertySearch/AreaList";
import { International_PAGE_URL } from "@components/common/site/constants";
import './searchoverlay.scss';
//import loadable from "@loadable/component";
//const SearchListBox = loadable(() => import("@components/PredictiveSearch/search-multiarea"));

const SearchOverlay = (props) => {

    // 
    // const [renderComponent, setRenderComponent] = useState(false);
    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])
    const [tab, settab] = useState('residential')
    const [selectedOption, setSelectedOption] = useState('buy');
    const [selectedOptionComm, setSelectedOptionComm] = useState('buy');
    const [selectedOptionOffplan, setSelectedOptionOffplan] = useState('properties');

	const tabChange = (key) => {
        if(key === "international"){
            navigate(International_PAGE_URL.alias)
        }
        else {
            settab(key)
        }
		
	}

	useEffect(() => {
        setAreaValResi('')
		setAreasArr([])
		setAreasList([])
	}, [tab])
    // 

    // Multi area search
    const [areaValResi, setAreaValResi] = useState('');

    const onChangeSellRent = (e) => {
        const changeSellRent = e.target.value
        setSelectedOption(changeSellRent);
    }

    const onChangeSellRentComm = (e) => {
        const changeSellRentComm = e.target.value
        setSelectedOptionComm(changeSellRentComm);
    }
    const onChangeOffplan = (e) => {
        const changeOffplan = e.target.value
        setSelectedOptionOffplan(changeOffplan);
    }
    
    const [areasList, setAreasList] = useState([]);
    const [areasArr, setAreasArr] = useState([]);
    
    const handleMultiAreaFun = (val) => {
        let areas = [];
        if (isEmpty(find(areasArr, val))) {
            areas = [...areasArr]
            areas.push(val)
            setAreasArr(areas)
        }
    }

    const removeArea = (area, clear=false) => {
        if (!clear) {
            let prevAreas = areasArr.filter( x => x.slug !== area)
            setAreasArr(prevAreas);
        } else {
            setAreasArr([]);
        }
    }
    // Multi area search

    const submitSearchResi = (e) => {
        // var areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
        var areaValuefetch = areasArr.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValue = areaValuefetch.filter(function (e) {return e != null;});
        var areaValue = removenullareaValue.map((items) => items).join("-and-")
        var developerfetch = areasArr.map((items) => items.isDeveloper === true ? items.slug.toLowerCase() : null)
        var removenulldeveloper = developerfetch.filter(function (e) {return e != null;});
        var developerValue = removenulldeveloper.map((items) => items).join("-and-")
        var searchFilterResults = "";

        if(areaValue) {
            if (selectedOption == "buy") {
                searchFilterResults = "/properties/for-sale/in-" + areaValue + "/";
            } 
            else if (selectedOption == "let") {
                searchFilterResults = "/properties/for-rent/in-" + areaValue + "/";
            }
            else {
                searchFilterResults = "/properties/short-term-for-rent/in-" + areaValue + "/";
            }
        } else {
            if (selectedOption == "buy") {
                searchFilterResults = "/properties/for-sale/in-dubai";
            } 
            else if (selectedOption == "let") {
                searchFilterResults = "/properties/for-rent/in-dubai";
            }
            else {
                searchFilterResults = "/properties/short-term-for-rent/in-dubai";
            }
        }

        if(developerValue){
            searchFilterResults += "/developer-" + developerValue + "/";
        }
        navigate(searchFilterResults);
        props.onRequestClose()
    }

    const submitSearchComm = (e) => {
        // var areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
        var areaValuefetch = areasArr.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValue = areaValuefetch.filter(function (e) {return e != null;});
        var areaValue = removenullareaValue.map((items) => items).join("-and-")
        var developerfetch = areasArr.map((items) => items.isDeveloper === true ? items.slug.toLowerCase() : null)
        var removenulldeveloper = developerfetch.filter(function (e) {return e != null;});
        var developerValue = removenulldeveloper.map((items) => items).join("-and-")
        
        var searchFilterResults = "";

        if(areaValue) {
            if (selectedOptionComm == "buy") {
                searchFilterResults = "/commercial-properties/for-sale/in-" + areaValue + "/";
            } else {
                searchFilterResults = "/commercial-properties/for-rent/in-" + areaValue + "/";
            }
        } else {
            if (selectedOptionComm == "buy") {
                searchFilterResults = "/commercial-properties/for-sale/in-dubai";
            } else {
                searchFilterResults = "/commercial-properties/for-rent/in-dubai";
            }
        }
        if(developerValue){
            searchFilterResults += "/developer-" + developerValue + "/";
        }
        navigate(searchFilterResults);
        props.onRequestClose()
    }

    const submitSearchOffplan = (e) => {
        // var areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")
        var areaValuefetch = areasArr.map((items) => items.isDeveloper !== true ? items.slug.toLowerCase() : null)
        var removenullareaValue = areaValuefetch.filter(function (e) {return e != null;});
        var areaValue = removenullareaValue.map((items) => items).join("-and-")
        var developerfetch = areasArr.map((items) => items.isDeveloper === true ? items.slug.toLowerCase() : null)
        var removenulldeveloper = developerfetch.filter(function (e) {return e != null;});
        var developerValue = removenulldeveloper.map((items) => items).join("-and-")
        var searchFilterResults = "";

        if(areaValue) {
            if (selectedOptionOffplan == "projects") {
            searchFilterResults = "/off-plan-projects/for-sale/in-" + areaValue + "/";
            }
            else {
                searchFilterResults = "/off-plan-properties/for-sale/in-" + areaValue + "/";
            }
        } else {
            if (selectedOptionOffplan == "projects") {
            searchFilterResults = "/off-plan-projects/for-sale/in-dubai/";
            }
            else {
                searchFilterResults = "/off-plan-properties/for-sale/in-dubai/";  
            }

        }
        if(developerValue){
            searchFilterResults += "/developer-" + developerValue + "/";
        }
        navigate(searchFilterResults);
        props.onRequestClose()
    }


    const submitSearchInternational = (e) => {
        var areaValue = areasArr.map((items) => items.slug.toLowerCase()).join("-and-")

        var searchFilterResults = "";

        if(areaValue) {
            searchFilterResults = "/international-properties/for-sale/in-" + areaValue + "/";
           
        } else {
            searchFilterResults = "/international-properties/for-sale/in-dubai";
           
        }


        navigate(searchFilterResults);
    }

    return (
        <React.Fragment>
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col lg={8}>
                        <div className="find-property">
                            <Tabs onSelect={(e) => tabChange(e)} defaultActiveKey="residential" id="uncontrolled-tab-example" className="search-nav-tab">
                                <Tab eventKey="residential" title="Residential">
                                    <div className="d-flex justify-content-start">
                                        <div className="search-overlay-box d-flex align-items-center">
                                            <div className="property-search-form">
                                                <div className="search-overlay-dropdown">
                                                    <Form.Select aria-label="Buy/Let" onChange={onChangeSellRent} className="property-search-form__select projects">
                                                        <option value="buy">For Sale</option>
                                                        <option value="let">For Rent</option>
                                                        <option value="shortterm">Short Term</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className="find-property-location-search location-search-residential">
                                                {/* {renderComponent && */}
                                                <SearchListBox 
                                                    areaVal={areaValResi} 
                                                    placeholder={"City, Community or Tower"} 
                                                    setAreaVal={setAreaValResi} 
                                                    setAreasList={setAreasList} 
                                                    handleMultiAreaFun={handleMultiAreaFun} 
                                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                                />
                                                {/* } */}
                                            </div>                                            
                                        </div>                                        
                                        <Button variant="secondary" type="button" onClick={submitSearchResi} className="submit-btn">
                                            <span className="d-md-inline"><StarberryIcons iconName="searchIcon" /></span>
                                        </Button>
                                    </div>
                                    <div className="predictive-arelist-suggestion search-overlay-suggestion">
                                        <AreaListBox areas={areasArr} removeArea={removeArea} />
                                    </div>
                                </Tab>
                                <Tab eventKey="commercial" title="Commercial">
                                    <div className="d-flex justify-content-start">
                                        <div className="search-overlay-box d-flex align-items-center">
                                            <div className="property-search-form">
                                                <div className="search-overlay-dropdown">
                                                    <Form.Select aria-label="Buy/Let" onChange={onChangeSellRentComm} className="property-search-form__select">
                                                        <option value="buy">For Sale</option>
                                                        <option value="let">For Rent</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className="find-property-location-search location-search-residential">
                                                <SearchListBox 
                                                    areaVal={areaValResi} 
                                                    placeholder={"City, Community or Tower"} 
                                                    setAreaVal={setAreaValResi} 
                                                    setAreasList={setAreasList} 
                                                    handleMultiAreaFun={handleMultiAreaFun} 
                                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                                />
                                            </div>                                            
                                        </div>                                        
                                        <Button variant="secondary" type="button" onClick={submitSearchComm} className="submit-btn">
                                            <span className="d-md-inline"><StarberryIcons iconName="searchIcon" /></span>
                                        </Button>
                                    </div>
                                    <div className="predictive-arelist-suggestion search-overlay-suggestion">
                                        <AreaListBox areas={areasArr} removeArea={removeArea} />
                                    </div>
                                </Tab>
                                <Tab eventKey="offplan" title="Off Plan">
                                    <div className="d-flex justify-content-start">
                                        <div className="search-overlay-box d-flex align-items-center">
                                        <div className="property-search-form">
                                                <div className="search-overlay-dropdown">
                                                    <Form.Select aria-label="Projects/Properties" onChange={onChangeOffplan} className="property-search-form__select projects">
                                                        <option value="properties">Properties</option>
                                                        <option value="projects">Projects</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className="find-property-location-search location-search-residential">
                                                <SearchListBox 
                                                    areaVal={areaValResi} 
                                                    placeholder={"Location or Developer"} 
                                                    setAreaVal={setAreaValResi} 
                                                    setAreasList={setAreasList} 
                                                    handleMultiAreaFun={handleMultiAreaFun} 
                                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                                />
                                            </div>                                            
                                        </div>                                        
                                        <Button variant="secondary" type="button" onClick={submitSearchOffplan} className="submit-btn">
                                            <span className="d-md-inline"><StarberryIcons iconName="searchIcon" /></span>
                                        </Button>
                                    </div>
                                    <div className="predictive-arelist-suggestion search-overlay-suggestion">
                                        <AreaListBox areas={areasArr} removeArea={removeArea} />
                                    </div>
                                </Tab>
                                {/* <Tab eventKey="international" title="International"> */}
                                    {/* <div className="d-flex justify-content-start">
                                        <div className="search-overlay-box d-flex align-items-center">
                                            <div className="find-property-location-search location-search-residential">
                                                <SearchListBox 
                                                    areaVal={areaValResi} 
                                                    placeholder={"Location or Developer"} 
                                                    setAreaVal={setAreaValResi} 
                                                    setAreasList={setAreasList} 
                                                    handleMultiAreaFun={handleMultiAreaFun} 
                                                    autosuggestInputProps={{className: 'react-autosuggest__input refine-autosuggest-input'}} 
                                                />
                                            </div>                                            
                                        </div>                                        
                                        <Button variant="secondary" type="button" onClick={submitSearchInternational} className="submit-btn">
                                            <span className="d-md-inline"><StarberryIcons iconName="searchIcon" /></span>
                                        </Button>
                                    </div>
                                    <div className="predictive-arelist-suggestion search-overlay-suggestion">
                                        <AreaListBox areas={areasArr} removeArea={removeArea} />
                                    </div> */}
                                {/* </Tab> */}
                            </Tabs>
                        </div>                        
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default SearchOverlay