import React from 'react';
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Label from './label';

const FileField = ({
    className,
    type,
    nolabel,
    name,
    value,
    required,
    placeholder,
    handlechange,
    pattern,
    accept,
    label,
    grpmd,
    groupClass,
    fieldClass,
    file,
    as
}) => (
    <Form.Group as={Col} md={grpmd} className={groupClass} controlId={"validation" + name}>
        {nolabel ? '' : <Label label={label} required={required} />}
        <div className={fieldClass}>
        <Form.Control
            as={as}
            className={className}
            required={required}
            type={type}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={handlechange}
            pattern={pattern}
            accept={accept}
            file={file}
            id="custom-file"
        />
        {/* <label for="custom-file" className="custom-file-label">{placeholder}</label> */}
        </div>
    </Form.Group>
);

export default FileField;