import React, { useEffect, useState} from "react";
import './popularSearches.scss';
import ReactMarkdown from "react-markdown/with-html";
import { useStaticQuery, graphql } from "gatsby"


const SearchLinks = (props) => {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        popularSearch(publicationState: LIVE) {
            Home_Layout_Search
            Property_Management_Search
            About_Search
            Commercial_Guide_Search
            Commercial_Leasing_Search
            Commercial_Sales_Search
            Commercial_Search
            Contact_Search
            Landlords_Search
            Media_Search
            Offplan_Sale_Search
            Offplan_Layout_Search
            Rent_Search
            Sell_Buy_Search
            Sellers_Buyers_Search
            Tenant_Search
            Luxury_Search
            Luxury_Sales_Search
            Vacation_Homes_Search
          }
      }
    }
  `)
  var footer_data = data.glstrapi.popularSearch;
  var popularSearchtype = props.popularSearch; 
    let layout;
    if(popularSearchtype == 'Home_Layout'){
      layout = footer_data.Home_Layout_Search
    }else if(popularSearchtype == 'Luxury_Sales_Layout'){ 
      layout = footer_data.Luxury_Sales_Search
    }else if(popularSearchtype == 'Property_Layout'){
      layout = footer_data.Property_Management_Search
    }else if(popularSearchtype == 'Sell_Buy_Layout'){
      layout = footer_data.Sell_Buy_Search
    }else if(popularSearchtype == 'Landlords_Layout'){
      layout = footer_data.Landlords_Search
    }else if(popularSearchtype == 'Sellers_Buyers_Layout'){
      layout = footer_data.Sellers_Buyers_Search
    }else if(popularSearchtype == 'Commercial_Sales_Layout'){
      layout = footer_data.Commercial_Sales_Search
    }else if(popularSearchtype == 'Commercial_Layout'){
      layout = footer_data.Commercial_Search
    }else if(popularSearchtype == 'Rent_Layout'){
      layout = footer_data.Rent_Search
    }else if(popularSearchtype == 'Tenant_Layout'){
      layout = footer_data.Tenant_Search
    }else if(popularSearchtype == 'Offplan_Layout'){
      layout = footer_data.Offplan_Layout_Search
    }else if(popularSearchtype == 'Offplan_Sale_Layout'){
      layout = footer_data.Offplan_Sale_Search
    }else if(popularSearchtype == 'Contact_Layout'){
      layout = footer_data.Contact_Search
    }else if(popularSearchtype == 'About_Layout'){
      layout = footer_data.About_Search
    }else if(popularSearchtype == 'Media_Layout'){
      layout = footer_data.Media_Search
    }else if(popularSearchtype == 'Commercial_Guide_Layout'){
      layout = footer_data.Commercial_Guide_Search
    }else if(popularSearchtype == 'Commercial_Leasing_Layout'){
      layout = footer_data.Commercial_Leasing_Search
    }else if(popularSearchtype == 'Luxury_Layout'){
      layout = footer_data.Luxury_Search
    }else if(popularSearchtype == 'Vacation_Homes_Layout'){
      layout = footer_data.Vacation_Homes_Search
    }else{
      layout = footer_data.Home_Layout_Search
    }  
    
 
   console.log('popularSearchtype', popularSearchtype)
    return(
        <>   
        <ReactMarkdown source={layout} escapeHtml={false}/>
       </>
    )
}

const PopularSearches = (props) => {
    const [render, setRender] = useState(false);
    
    useEffect(()=>{
      
      setTimeout(()=>{
        if(!render){
          setRender(true)
        }
      },1000)
    })

    return(

        <div className="popular-searches">
          <div className={"popular-search-block"}>
            <div className="popular-search-head" >
              <span>{props?.trending_searches ? props?.trending_searches : "Trending Searches"}</span>
            
            </div>
            { render &&
            <SearchLinks popularSearch={props.popularSearch} ></SearchLinks>
            }
            </div>
        </div>

    )
}

export default PopularSearches
