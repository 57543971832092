import React from "react";
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal'
import ModuleTitle from '@components/modules/Title';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { StarberryIcons } from "@components/icons/index";

import './modal.scss';

function ModalWrapper({
        children,
        show,
        onHide,
        title,
        showFooter,
        onRequestClose,
        className,
        ...rest
    }) {
    return (
        <Modal
            size="lg"
            centered
            show={show}
            onHide={onHide ? onHide : onRequestClose}
            className={`custom-modal${className ? " " + className : ""}`}
            {...rest}
        >
            <Button variant="none" onClick={onHide} className="custom-btn-close"><StarberryIcons iconName="closeIcon" /></Button>
            <Modal.Header>
                {title &&
                    <Modal.Title className="custom-modal-title">
                        <Row>
                            <Col>
                                <ModuleTitle
                                    as="span"
                                    title={title}
                                />
                            </Col>
                        </Row>
                    </Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            {showFooter &&
                <Modal.Footer>
                    <Button onClick={onHide}>Close</Button>
                </Modal.Footer>
            }
        </Modal>
    );
}

function ModalPortal({ children, ...rest }) {
    return (
        <>
            <ModalWrapper {...rest}>
                {children}
            </ModalWrapper>
        </>
    );
}

export default ModalPortal