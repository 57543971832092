const Routes = {
    HomePage: '/',
    ContactUsPage: '/contact-exclusive-links',
    ListYourProperty: '/list-your-property',
    LuxuryProperty: '/luxury-property-dubai/',
    RequestValuation: '/property-valuation-in-dubai',
    VideosPage: '/uae-property-videos',
    PodcastsPage: 'uae-property-podcast',
    TeamsPage: '/meet-the-team',
    AboutPage: '/about-exclusive-links',
    MediaPage: '/media',
    BlogPage: '/uae-property-blog',
    NewsPage: '/uae-property-news-letter',
    JobsPage: '/real-estate-recruitment-in-uae',
    Buy: '/properties/for-sale/in-dubai',
    BuyProperty: '/buy-property-in-uae',
    Sell: '/sell-your-property-in-uae',
    Rent: '/properties/for-rent/in-dubai',
    OffPlan: '/off-plan-properties-in-uae',
    PropertyManagement: '/property-management',
    AreaGuides: '/uae-area-guides',
    Reviews: '/customer-reviews',
    NotFoundPage: '/404',
    SalesProperties: '/properties/for-sale/in-dubai',
    VacationHomes: '/vacation-homes-in-dubai',
    VacationHomesCustomerReviews: '/vacation-homes-in-dubai/vacation-home-customer-reviews',
    RequestProjection:'/request-projection/',
    OffPlanProperties:'/off-plan-projects/for-sale/in-dubai',
    VacationHomesProperties:'/properties/short-term-for-rent/in-dubai' 
}

module.exports = {};
Object.entries(Routes).forEach(([key, value]) => {
    module.exports[key] = value;
});

module.exports.Routes = Routes;
