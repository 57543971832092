import React, { useState } from 'react';
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import loadable from "@loadable/component";
import Label from './label';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const DatePicker = loadable(() => import("react-datepicker"));

const DateField = ({
    className,
    // type,
    nolabel,
    name,
    value,
    required,
    // placeholder,
    // handlechange,
    // pattern,
    label,
    grpmd,
    groupClass,
    // as,
    // startDate,
    // setStartDate
}) => {
    const [startDate, setStartDate] = useState();
    const minDate = new Date();
    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };
    return(
        <Form.Group as={Col} md={grpmd} className={groupClass} controlId={"validation" + name}>
            {nolabel ? '' : <Label label={label} required={required} />}
            <DatePicker
            minDate={minDate}
            name={name}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                showTimeSelect
                filterTime={filterPassedTime}
                dateFormat="MMMM d, yyyy h:mm aa"
                value={value}
                className={`form-control${className ? className : ""}`}
            />
        </Form.Group>
    )
}

export default DateField;