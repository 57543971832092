import React from 'react';
import Stack from 'react-bootstrap/Stack';
import Link from '@components/ButtonLink';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Routes from '@components/routes';

const MenusFooter = (props) => {
    const services_menu = props.footer_content?.services_menu;
    const others_menu = props.footer_content?.others;
    return(
        <Row className="menus-footer d-none d-md-flex">
            <Col md={6} lg={{span: 5, offset: 1}}>
                <Stack direction="vertical" className="gap-4 gap-lg-8">
                    <h5 className="menus-footer__title mb-14 mb-lg-10">{services_menu?.services ? services_menu?.services : "Services"}</h5>
                    <Link variant="none" manualLink={`${Routes.Sell}`} className="menus-footer__link">{ services_menu?.sell ? services_menu?.sell : "Sell"}</Link>
                    <Link variant="none" manualLink={`${Routes.BuyProperty}`} className="menus-footer__link">{services_menu?.buy ? services_menu?.buy : "Buy Property"}</Link>
                    <Link variant="none" manualLink={`${Routes.Rent}`} className="menus-footer__link">{services_menu?.rent ? services_menu?.rent : "Rent"}</Link>
                    <Link variant="none" manualLink={`${Routes.VacationHomes}`} className="menus-footer__link"> {services_menu?.vacation_homes ? services_menu?.vacation_homes : "Vacation Homes" }</Link>
                    <Link variant="none" manualLink={`${Routes.OffPlan}`} className="menus-footer__link"> {services_menu?.off_plan ? services_menu?.off_plan : "Off Plan"}</Link>
                    <Link variant="none" manualLink={`${Routes.PropertyManagement}`} className="menus-footer__link"> { services_menu?.property_management ? services_menu?.property_management : "Property Management" }</Link>
                    <Link variant="none" manualLink={`${Routes.LuxuryProperty}`} className="menus-footer__link">{ services_menu?.luxury_property ? services_menu?.luxury_property : "Luxury Property" }</Link>
                </Stack>
            </Col>
            <Col md={6} lg={{span: 5, offset: 1}}>
                <Stack direction="vertical" className="gap-4 gap-lg-8">
                    <h5 className="menus-footer__title mb-14 mb-lg-10">{others_menu?.others ? others_menu?.others : "Other" }</h5>
                    <Link variant="none" manualLink={`${Routes.MediaPage}`} className="menus-footer__link"> {others_menu?.media ? others_menu?.media : "Media" }</Link>
                    <Link variant="none" manualLink={`${Routes.AboutPage}`} className="menus-footer__link">{others_menu?.about ? others_menu?.about : "About" }</Link>
                    <Link variant="none" manualLink={`${Routes.AboutPage}${Routes.TeamsPage}`} className="menus-footer__link"> {others_menu?.meet_the_team ? others_menu?.meet_the_team : "Meet the Team"} </Link>
                    <Link variant="none" manualLink={`${Routes.AboutPage}${Routes.Reviews}`} className="menus-footer__link">{others_menu?.reviews ? others_menu?.reviews : "Reviews" }</Link>
                    <Link variant="none" manualLink={`${Routes.AboutPage}${Routes.JobsPage}`} className="menus-footer__link">{others_menu?.recuirement ? others_menu?.recuirement : "Recruitment"}</Link>
                    <Link variant="none" manualLink={`${Routes.ContactUsPage}`} className="menus-footer__link">{others_menu?.contact ? others_menu?.contact : "Contact" }</Link>
                </Stack>
            </Col>
        </Row>
    )
}

export default MenusFooter
