import React, {useState, useEffect} from "react";
import InputField from './field/input'
import FileField from './field/file'
import SelectField from './field/select'
// import TextAreaField from './field/textarea'
// import CheckboxField from './field/checkbox'
import ButtonField from './field/button'
import HtmlBox from './field/html'
import RadioField from './field/radio'
import DateField from './field/datetime'
import ReCaptchaBox from './field/recaptcha'
function phoneNumberValidation(num)  {
    if(num){
      return num.toString().replace(/[^0-9\+ ]+/g, "")
    } else {
      return false;
    } 
  }
const FieldRender = ({ fields, handleonVerify, flag, recaptchaRef, email, handleAttachment, setEmail, setTopic }) => {
    const [selecttype, setSelecttype] = useState()
    const [fieldsVal, setFieldsVal] = useState(fields)
    const [flagVal, setFlagVal] = useState();


    useEffect(() => {
      setFlagVal(flag)
    }, [flagVal])
    useEffect(() => {
      var pagepath = "";
      if(typeof window !== 'undefined'){
        if(window.sessionStorage.getItem("Page") !== undefined){
          pagepath = window.sessionStorage.getItem("Page")
          sessionStorage.removeItem("Page")
        }
        if(pagepath && pagepath.indexOf("sell-your-property-in-uae") > -1 || pagepath && pagepath.indexOf("buy-property-in-uae") > -1){
          setSelecttype("sale")
        }
        else if(pagepath && pagepath.indexOf("rent-a-property-in-uae") > -1 ){
          setSelecttype("rent")
        }
        else if(pagepath && pagepath.indexOf("vacation-homes-in-dubai") > -1 ){
          setSelecttype("short")
        } 
        
      }
      
    }, [selecttype]);
      const handlechange = event => {
        // remove initial empty spaces
        //console.log("select_event",event);
       
          if(event.target.name === "telephone"){
            var valid_phone = phoneNumberValidation(event.target.value);
            if(valid_phone){
              event.target.value = valid_phone;
            } else{
              event.target.value = '';
            }
          }
          else if(event.target.name === "email" && email){
            setEmail("")
            // event.target.value = event.target.value.trimStart()
          }
        //   else if(event.target.name === "type"){
        //     selecttype = event.target.value
        //   }
          else{
            event.target.value = event.target.value.trimStart()
          }
       
    
      }

    
    return (
        <>
            {fieldsVal?.map((field, index) => {
                switch(field.type) {
                    case 'text':
                    case 'email':
                    case 'tel':
                    case 'hidden':
                        return(
                            <InputField handlechange={handlechange} pattern={field.patternchk} email={email} {...field} key={"key_" + field.name} />
                        )
                    case 'submit':
                        return(
                            <ButtonField {...field} key={"key_" + field.name} />
                        )
                    case 'html':
                        return(
                            <HtmlBox {...field} key={"key_" + field.name} />
                        )
                    case 'radio':
                    case 'checkbox':
                    case 'switch':
                        return(
                            <RadioField setTopic={setTopic} {...field} key={"key_" + field.name} />
                        )
                    case 'file':
                        return(
                            <FileField handlechange={handleAttachment} {...field} key={"key_" + field.name} />
                        )
                    case 'date':
                        return(
                            <DateField {...field} key={"key_" + field.name} />
                        )
                    case 'select':
                        return(
                            <SelectField setSelecttype={setSelecttype} selecttype={selecttype}  handlechange={handlechange} {...field} key={"key_" + field.name} />
                        )
                        case 'captcha':
                            return(
                                <ReCaptchaBox
                                fieldClass={field.class}
                                captRef={recaptchaRef}
                                key={`${field.element}~${index}`}
                                handleonVerify={handleonVerify}
                              />
                            )
                    case 'p':
                        return(
                            <p className={field?.className} key={"key_" + field?.name}>{field?.text}</p>
                        )
                    default:
                        return null;
                }
            })}
        </>
    )
}

export default FieldRender;