import React from 'react';
import { Button, Stack } from "react-bootstrap";

const ButtonField = ({ type, name, variant, className, groupClass}) => (
	<Stack direction="vertical" className={`flex-md-row gap-16 ${groupClass}`}>
		<Button variant={variant} type={type} className={className}>
			{name}
		</Button>
	</Stack>
);

export default ButtonField;

