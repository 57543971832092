import * as React from "react";
import Accordion from 'react-bootstrap/Accordion';
import ButtonLink from '@components/ButtonLink';

import { containerVariants, delayMenuItems } from '@components/utils/animations';

import './menu.scss';

const AccordionItem = Accordion.Item;

const ParentLink = ({ menuTree, show, handleClose }) => {
    return(
        <Accordion
            initial="hidden"
            animate={show ? "visible" : "hidden"}
            variants={containerVariants}
            flush
            className="main-menu-accordion"
        >
            {menuTree?.map((menu, i) => {
                return(
                    <AccordionItem variants={delayMenuItems} custom={i + 6} eventKey={menu.id} key={menu.id} className="parent-link">
                        {menu.childNodes.length > 0 ?
                            <Accordion.Header><ButtonLink burgermenu={true} handleClose={handleClose} {...menu.Link} variant="link" key={menu?.id} /></Accordion.Header>
                        :
                            <ButtonLink burgermenu={true} handleClose={handleClose} {...menu.Link} variant="link" key={menu?.id} />
                        }
                        {menu.childNodes.length > 0 &&
                            <Accordion.Body>
                                {menu.childNodes.map((child) => {
                                    return(
                                        <Accordion flush key={child.id}>
                                            <Accordion.Item eventKey={child.id} key={child.id} className="child-link-one">
                                                {child.childNodes.length > 0 ?
                                                    <>
                                                        <Accordion.Header><ButtonLink burgermenu={true} handleClose={handleClose} {...child.Link} variant="link" key={child.id} /></Accordion.Header>
                                                        <Accordion.Body>
                                                            {child.childNodes.map((child) => {
                                                                
                                                                return(
                                                                    <Accordion.Item eventKey={child.id} key={child.id} className="child-link-two">
                                                                        {child.length > 0 ?
                                                                            <Accordion.Header><ButtonLink burgermenu={true} handleClose={handleClose} {...child.Link} variant="link" key={child?.id} /></Accordion.Header>
                                                                        :
                                                                            <ButtonLink burgermenu={true} handleClose={handleClose} {...child.Link} variant="link" key={child?.id} />
                                                                        }
                                                                    </Accordion.Item>
                                                                )
                                                            })}
                                                        </Accordion.Body>
                                                    </>
                                                :
                                                    <ButtonLink burgermenu={true} handleClose={handleClose} {...child.Link} variant="link" key={child?.id} />
                                                }
                                            </Accordion.Item>
                                        </Accordion>
                                    )
                                })}
                            </Accordion.Body>
                        }
                    </AccordionItem>
                )
            })}
        </Accordion>
    )
}

export default ParentLink