import React from 'react'
import { Form } from "react-bootstrap"
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";

const RadioboxField = ({
    name,
    required,
    placeholder,
    values,
    handlechange,
    className,
    groupClass,
    grpmd,
    type,
    legend,
    legendClass,
    setTopic
}) => {
const handlechangeradio = (e) => {
    setTopic(e.target.value)
    sessionStorage.setItem("topic", e.target.value)
}
    return(
    <Form.Group as={Col} md={grpmd} controlId={"validation" + name} className={groupClass}>
        {legend && <legend className={legendClass}>{legend}</legend>}
        <Stack key={name} className="gap-8 gap-sm-48 flex-sm-row">
            
            {values.map((val, i) =>
                <Form.Check
                    key={i}
                    type={type}
                    value={val.value}
                    onChange={handlechangeradio}
                    {...val}
                />
            )}
        </Stack>
    </Form.Group>

           ) }

export default RadioboxField;