/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import Helmet from 'react-helmet'
 import Header from "@components/header/header"
 import Footer from "@components/footer"
 import { ModalProvider } from '@components/modal/ModalContext';
 import ModalRoot from '@components/modal/ModalRoot';
 import SSRProvider from 'react-bootstrap/SSRProvider';
 
 const Layout = ({ children, enbaleFrenchPage, enbaleRightAlign, bodyClass, headerClass, loading, popularSearch, menu_list, footer_content }) => {
 
    //  React.useEffect(()=>{
    //      const currentUrl=typeof window!==undefined&&window.location.href;
    //      const url =currentUrl.split("/")
 
    //      if(url.includes("?property=commercial")&&url.includes("rent")){
             
    //          window.location.href="/commercial-properties/for-rent/in-dubai/"
    //      }
    //      if(url.includes("?property=commercial")&&url.includes("sales")){
            
    //          window.location.href="/commercial-properties/for-sale/in-dubai/"
    //      }
        
         
    //  },[])

     return (
         <SSRProvider>
             {bodyClass &&
                 <Helmet
                     bodyAttributes={{
                         class: bodyClass
                     }}
                 />
             }
             <ModalProvider>
                <div className={`${enbaleRightAlign && "arabic_page_enabled"} ${enbaleFrenchPage && "french_page_enabled"}`} >
                    <Header enbaleRightAlign={enbaleRightAlign} classNameHeader={headerClass} menu_list={menu_list} />
                    <main>{children}</main>
                    <Footer popularSearch={popularSearch} footer_content={footer_content} enbaleRightAlign={enbaleRightAlign} />
                    <ModalRoot />
                 </div>
             </ModalProvider>
             <div id="modal-portal"></div>
         </SSRProvider>
     )
 }
 
 Layout.propTypes = {
     children: PropTypes.node.isRequired,
 }
 
 export default Layout
 