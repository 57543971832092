import React, { useState, useEffect } from "react";
import ErrorIcon from "./ErrorIcon";

export const StarberryIcons = (props) => {
    const {
        className,
        iconName,
        iconStyle
    } = props;

    const [DynamicComponent, setDynamicComponent] = useState(null);

    useEffect(() => {
        const loadComponent = async () => {
            if (iconName && iconName !== "") {
                try {
                    let myiconName = iconName.charAt(0).toUpperCase() + iconName.slice(1);
                    const { default: component } = await import(`./${myiconName}`);
                    setDynamicComponent(() => component);
                } catch (error) {
                    console.warn(`Icon ${iconName} not found`, error);
                    setDynamicComponent(() => ErrorIcon);
                }
            }
        };

        loadComponent();
    }, [iconName]);

    if (!DynamicComponent) {
        // Render loading state or placeholder while waiting for the component to load
        return null;
    }

    // Ensure that DynamicComponent is a valid React component before rendering
    if (typeof DynamicComponent !== "function") {
        console.warn(`Invalid component type for icon ${iconName}:`, DynamicComponent);
        return <ErrorIcon className={className} style={iconStyle} />;
    }

    // Render the dynamically imported component
    return <DynamicComponent className={className} style={iconStyle} />;
};

export default StarberryIcons;
