import React from 'react';
import { Form } from "react-bootstrap";

const Label = ({ label, required }) => (
    <>
    {label &&
        <Form.Label>{label}{required ? <span className="required"> *</span> : ''}</Form.Label>
    }
    </>
);

export default Label;

