import * as React from "react";
import { useStaticQuery, graphql } from "gatsby"

import ParentLink from '@components/navigation/parent';

const Navigation = ({ show, limit, handleClose }) => {
    // let hashTable = Object.create(null)
    const createDataTree = dataset => {
        let hashTable = Object.create(null)
        // add a childNodes array to each node
        dataset?.forEach( aData => hashTable[aData.id] = { ...aData, childNodes : [] } )
        let dataTree = []
        // loop over nodes, the child node has to have the Parent id
        dataset?.forEach( aData => {
            if (limit) {
                if (aData.showInSectorDropdown) {
                    // push the child node under the parent node
                    if( aData.Parent ) {
                        hashTable[`${aData.Parent.id}`].childNodes.push(hashTable[aData.id])
                    } else {
                        // this is the normal parent node
                        dataTree.push(hashTable[aData.id])
                        
                    }
                } else {
                    return null
                }
            } else {
                // push the child node under the parent node
                if( aData.Parent ) {
                    hashTable[`${aData.Parent.id}`].childNodes.push(hashTable[aData.id])
                } else {
                    // this is the normal parent node
                    dataTree.push(hashTable[aData.id])
                    
                }
            }
        })
        return dataTree
    }
    const data = useStaticQuery(graphql`
        {
            glstrapi {
                menus(publicationState: LIVE, sort: "Sort:ASC") {
                    id
                    Sort
                    Slug
                    Label
                    showInSectorDropdown
                    Parent {
                        id
                        Parent {
                            id
                        }
                    }
                    Link {
                        id
                        manualLink
                        label
                        icon
                        btnClass
                        page {
                            Slug
                            id
                            Parent {
                                id
                                Slug
                            }
                            Parent2 {
                                id
                                Slug
                            }
                            Parent3 {
                                Slug
                                id
                            }
                        }
                    }
                }
            }
        }
    `)
    const menuTree = createDataTree(data.glstrapi.menus);
    return(
        <ParentLink handleClose={handleClose} menuTree={menuTree} show={show} />
    )
}

export default Navigation