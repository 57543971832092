export const URLS = {
  
  AreaGuide: {
    name: 'Area Guide',
    alias: "/buy-property-in-uae/uae-area-guides"
  },
  International: {
    name: 'International',
    alias: "/buy-property-in-uae/international-property-sales"
  }
}
export const VacationHomesData = {
    contactData: {
        Name: "Exclusive Links Vacation Homes",
        Department: "short_term",
        Email: "reservations@exclusive-links.com",
        LogoImage: {
            url: "https://ggfx-exclusivelinks.s3.eu-west-2.amazonaws.com/i.prod/VH_GREEN_a1ef017296.webp",
            alternativeText: "Vacation Homes Logo",
        },
        Phone: "+971 4 399 4937",
        WhatsApp: " +971 50 952 0898"
    }
}

export const VactionHomesContactData = VacationHomesData.contactData;

export const AreaGuide_PAGE_URL = URLS.AreaGuide;
export const International_PAGE_URL = URLS.International;

